import "./Empty.css";

interface IField {
  field?: string;
}

const Empty: React.FC<IField> = ({ field }) => {
  return <div className="empty">no {field} here yet...</div>;
};

export default Empty;
