import { useState } from "react";
import Button from "../shared/Button/Button";
import List from "../shared/list/List";
import Title from "../shared/title/Title";
import "./Posts.css";
import Modal from "../shared/Modal/Modal";
import PostForm from "./components/PostForm/PostForm";
import ImageUpload from "../shared/ImageUpload/ImageUpload";
import moment from "moment";

interface IPost {
  title: string;
  date: string;
  text: string;
  image?: File;
  thumbnail?: File;
}

const Posts: React.FC = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [image, setImage] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [modalToggle, setModalToggle] = useState(false);
  const [post, setPost] = useState({
    title,
    text,
    date: moment().format("LL"),
    image,
    thumbnail,
  });
  const openModal = () => {
    setModalToggle(true);
  };
  const closeModal = () => {
    setModalToggle(false);
  };
  const submit = (
    e: any,
    title: string,
    text: string,
    image: string,
    thumbnail: string,
    setPost: any
  ) => {
    e.preventDefault();
    const newPost = {
      title,
      text,
      date: moment().format("LL"),
      image,
      thumbnail,
    };
    // ! same problem as lingoberry state dose not update immediately
    setPost(newPost);
    const newData: any = data;
    const changeData: any = newData.push(post);
    setData(newData);
    closeModal();
  };
  const [data, setData] = useState<Array<IPost>>([
    {
      title: "file-title-1",
      date: moment().format("LL"),
      text: "this is a test text",
    },
    {
      title: "file-title-2",
      date: moment().format("LL"),
      text: "this is a test text",
    },
    {
      title: "file-title-1",
      date: moment().format("LL"),
      text: "this is a test text",
    },
    {
      title: "file-title-2",
      date: moment().format("LL"),
      text: "this is a test text",
    },
    {
      title: "file-title-1",
      date: moment().format("LL"),
      text: "this is a test text",
    },
    {
      title: "file-title-2",
      date: moment().format("LL"),
      text: "this is a test text",
    },
  ]);
  const changeData = (index: number, currentData?: any, changedPost?: any) => {
    const newData: IPost[] = data.filter((e) => e !== data[index]);
    const changedData: any = currentData ? newData.push(changedPost) : newData;
    setData(newData);
    //todo push to server!
  };
  return (
    <div>
      <Title title="Posts" />
      <div className="table">
        <List
          headers={[" ", "Title", "Date", "Summery", "Edit", "Delete"]}
          data={data}
          title={"posts"}
          changeData={changeData}
        ></List>
        <div onClick={openModal}>
          <Button></Button>
        </div>
      </div>
      <Modal toggler={modalToggle} close={closeModal}>
        <>
          <div className="wrapper-uploader">
            <ImageUpload setFile={setImage}></ImageUpload>
            <ImageUpload setFile={setThumbnail}></ImageUpload>
          </div>

          <PostForm
            setTitle={setTitle}
            setText={setText}
            submit={submit}
            title={title}
            text={text}
            image={image}
            thumbnail={thumbnail}
            setPost={setPost}
          ></PostForm>
          {/* <p>p</p> */}
        </>
      </Modal>
    </div>
  );
};

export default Posts;
