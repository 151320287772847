import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import Layout from './components/layout/Layout';
import Login from './components/Login/Login';
import Media from './components/Media/Media';
import Messages from './components/Messages/Messages';
import Posts from './components/Posts/Posts';
import Requests from './components/Requests/Requests';

function App() {
  const [modeSwitch, setModeSwitch] = useState(false);
  return (
    <>
      <Routes>
        {/*
         TODO Add auth  
        */}
        <Route path='login' element={<Login />} />
        <Route
          path='/'
          element={<Layout modeSwitch={modeSwitch} setModeSwitch={setModeSwitch} />}
        >
          <Route index element={<Home />} />
          <Route path='media' element={<Media />} />
          <Route path='messages' element={<Messages />} />
          <Route path='posts' element={<Posts />} />
          <Route path='Requests' element={<Requests />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
