import { useState } from "react";
import "./PostForm.css";
interface IPost {
  setTitle: any;
  setText: any;
  submit: any;
  title?: string;
  text?: string;
  image?: string;
  thumbnail?: string;
  setPost?: any;
  currentPost?: ICPost;
  changeData?: any;
}
interface ICPost {
  title: string;
  date: string;
  text?: string;
  image?: File;
  thumbnail?: File;
}

const PostForm: React.FC<IPost> = ({
  setTitle,
  setText,
  submit,
  image,
  text,
  thumbnail,
  title,
  setPost,
  currentPost,
  changeData,
}) => {
  const handleTitle = (e: any) => {
    setTitle(e.target.value);
  };

  const handleText = (e: any) => {
    setText(e.target.value);
  };

  return (
    <form className="form">
      <input
        type="text"
        onChange={handleTitle}
        className="input"
        placeholder={currentPost ? currentPost.title : "title"}
        // value={currentPost ? (title ? title : currentPost.title) : title}
      />
      <textarea
        name="textarea"
        onChange={handleText}
        className="textarea"
        placeholder={currentPost ? currentPost.text : "text"}
        // value={currentPost ? (text ? text : currentPost.text) : text}
      ></textarea>
      <button
        className="submit"
        onClick={(e) =>
          submit(e, title, text, image, thumbnail, setPost, changeData)
        }
      >
        post
      </button>
    </form>
  );
};

export default PostForm;
