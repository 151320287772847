import "./Login.css";
import Key from "../../assets/key.svg";
import { Link } from "react-router-dom";

const Login: React.FC = () => {
  return (
    <div className="login-background">
      <div className="login-card">
        {/* todo awag icon goes here */}
        <img src={Key} alt="key" className="login-icon" />
        <form className="login-form">
          <input
            className="login-input"
            placeholder="username"
            required
            type="text"
          />
          <input
            type="password"
            className="login-input"
            placeholder="password"
            required
          />
        </form>
        <Link to="/">
          <button className="login-submit">sign in</button>
        </Link>
      </div>
    </div>
  );
};
export default Login;
