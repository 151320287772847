import { useState } from "react";
import Empty from "../shared/Empty/Empty";
import List from "../shared/list/List";
import RequestList from "../shared/RequestList/RequestList";
import Title from "../shared/title/Title";
import "./Requests.css";

const Requests: React.FC = () => {
  const [requestsList, setRequestsList] = useState([
    {
      fullName: "test1",
      phoneNumber: "000",
      email: "qw@er",
      service: "atm",
      address: "home avenue",
      doorNumber: "215",
      city: "test city",
      state: "magic",
      postalCode: "111",
      country: "turkey",
      text: "text",
      newsLetter: true,
      policy: true,
    },
    {
      fullName: "test2",
      phoneNumber: "000",
      email: "qw@er",
      service: "atm",
      address: "home avenue",
      doorNumber: "215",
      city: "test city",
      state: "magic",
      postalCode: "111",
      country: "turkey",
      text: "text",
      newsLetter: true,
      policy: true,
    },
  ]);
  const changeData = (index: number) => {
    const newData = requestsList.filter((e) => e !== requestsList[index]);
    setRequestsList(newData);
    //todo push to server!
  };
  return (
    <div>
      <Title title="Requests" />
      <RequestList
        data={requestsList}
        changeData={changeData}
        headers={[
          "",
          "full name",
          "phone number",
          "email",
          "service",
          "info",
          "delete",
        ]}
      />
    </div>
  );
};

export default Requests;
