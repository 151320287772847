import { useState } from "react";
import PostForm from "../../Posts/components/PostForm/PostForm";
import Empty from "../Empty/Empty";
import ImageUpload from "../ImageUpload/ImageUpload";
import Modal from "../Modal/Modal";
import "./List.css";
interface IList {
  data: IPost[];
  headers?: any;
  sizes?: any;
  title?: string;
  changeData?: any;
}
interface IPost {
  title: string;
  date: string;
  text: string;
  image?: File;
  thumbnail?: File;
}
const List: React.FC<IList> = ({ data, headers, title, changeData }) => {
  const [modalToggle, setModalToggle] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const openModal = (d: IPost, index: number) => {
    setModalToggle(true);
    setCurrentData(d);
    setCurrentIndex(index);
  };
  const closeModal = () => {
    setModalToggle(false);
  };
  const [postTitle, setPostTitle] = useState("");
  const [text, setText] = useState("");
  const [image, setImage] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [currentData, setCurrentData] = useState(data[0]);
  const [post, setPost] = useState({
    postTitle: currentData.title,
    text: currentData.text,
    image: currentData.image,
    thumbnail: currentData.thumbnail,
  });
  const submit = (
    e: any,
    title: string,
    text: string,
    image: File,
    thumbnail: File,
    setPost: any,
    changeData?: any
  ) => {
    e.preventDefault();
    setPost({
      postTitle: title,
      text,
      image,
      thumbnail,
    });
    changeData(currentIndex, currentData, post);
    closeModal();
  };
  console.log(data);
  return (
    <>
      {data ? (
        <table className="fixed_headers">
          <thead>
            <tr>
              {headers.map((h: string) => (
                <th>{h}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((d: any, index: number) => (
              <tr>
                <td>
                  <i className="bx bxs-video-recording list-icon"></i>
                </td>
                <td>{d.title}</td>
                <td>{d.date}</td>
                <td>{d.text.substr(0, 40)} ...</td>
                {changeData ? (
                  <>
                    <td onClick={() => openModal(d, index)}>
                      <i className="bx bxs-message-square-edit list-icon"></i>
                    </td>
                    <td
                      onClick={() => {
                        changeData(index);
                      }}
                    >
                      <i className="bx bxs-trash-alt list-icon"></i>
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <i className="bx bxs-message-square-edit list-icon"></i>
                    </td>
                    <td>
                      <i className="bx bxs-trash-alt list-icon"></i>
                    </td>
                  </>
                )}
                <Modal toggler={modalToggle} close={closeModal}>
                  <>
                    <div className="wrapper-uploader">
                      <ImageUpload
                        setFile={setImage}
                        currentImage={currentData.image}
                      ></ImageUpload>
                      <ImageUpload
                        setFile={setThumbnail}
                        currentImage={currentData.thumbnail}
                      ></ImageUpload>
                    </div>

                    <PostForm
                      setTitle={setPostTitle}
                      setText={setText}
                      submit={submit}
                      title={postTitle}
                      text={text}
                      image={image}
                      thumbnail={thumbnail}
                      setPost={setPost}
                      currentPost={currentData}
                      changeData={changeData}
                    ></PostForm>
                    {/* <p>p</p> */}
                  </>
                </Modal>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Empty field={title} />
      )}
    </>
  );
};

export default List;
