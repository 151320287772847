import Empty from "../shared/Empty/Empty";
import Title from "../shared/title/Title";
import "./Messages.css";

const Messages: React.FC = () => {
  return (
    <div>
      <Title title="Messages" />
      <Empty field="messages"></Empty>
    </div>
  );
};

export default Messages;
