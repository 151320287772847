import Card from '../shared/card/Card';
import Empty from '../shared/Empty/Empty';
import Title from '../shared/title/Title';
import './Home.css';

const Home: React.FC = () => {
  return (
    <>
      <Title title='Dashboard' />
      <div className='cards-container'>
        <Card
          title='Messages'
          status={false}
          icon='bx bx-message-square-detail card-icon'
        >
          <Empty field='messages'></Empty>
        </Card>
        <Card title='Requests' status={false} icon='bx bx-git-pull-request card-icon'>
          <Empty field='requests'></Empty>
        </Card>
        <Card title='Posts' status={false} icon='bx bx-heart card-icon'>
          <Empty field='posts'></Empty>
        </Card>
      </div>
    </>
  );
};

export default Home;
