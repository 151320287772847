import { useState } from "react";
import Empty from "../Empty/Empty";
import Modal from "../Modal/Modal";
import "./RequestList.css";
interface IList {
  data?: any;
  headers?: any;
  sizes?: any;
  title?: string;
  changeData?: any;
}
const RequestList: React.FC<IList> = ({ data, headers, title, changeData }) => {
  const [modalToggle, setModalToggle] = useState(false);
  const [currentData, setCurrentData] = useState(data[0]);
  const openModal = (d: {}) => {
    setModalToggle(true);
    setCurrentData(d);
  };
  const closeModal = () => {
    setModalToggle(false);
  };
  return (
    <>
      {data ? (
        <table className="fixed_headers">
          <thead>
            <tr>
              {headers.map((h: string) => (
                <th>{h}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((d: any, index: number) => (
              <tr>
                <td>
                  <i className="bx bx-git-pull-request list-icon"></i>
                </td>
                <td>{d.fullName}</td>
                <td>{d.phoneNumber}</td>
                <td>{d.email}</td>
                <td>{d.service}</td>
                {changeData ? (
                  <>
                    <td onClick={() => openModal(d)}>
                      <i className="bx bx-info-circle list-icon"></i>
                    </td>
                    <td
                      onClick={() => {
                        changeData(index);
                      }}
                    >
                      <i className="bx bxs-trash-alt list-icon"></i>
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <i className="bx bx-info-circle list-icon"></i>
                    </td>
                    <td>
                      <i className="bx bxs-trash-alt list-icon"></i>
                    </td>
                  </>
                )}
                <Modal toggler={modalToggle} close={closeModal}>
                  <div className="info-container">
                    <div className="info-row">
                      <div className="info-title">full name</div>
                      <div className="info-name">{currentData.fullName}</div>
                    </div>
                    <div className="info-row">
                      <div className="info-title">phone number</div>
                      <div className="info-name">{currentData.phoneNumber}</div>
                    </div>
                    <div className="info-row">
                      <div className="info-title">email</div>
                      <div className="info-name">{currentData.email}</div>
                    </div>
                    <div className="info-row">
                      <div className="info-title">service</div>
                      <div className="info-name">{currentData.service}</div>
                    </div>
                    <div className="info-row">
                      <div className="info-title">address</div>
                      <div className="info-name">{currentData.address}</div>
                    </div>
                    <div className="info-row">
                      <div className="info-title">door number</div>
                      <div className="info-name">{currentData.doorNumber}</div>
                    </div>
                    <div className="info-row">
                      <div className="info-title">city</div>
                      <div className="info-name">{currentData.city}</div>
                    </div>
                    <div className="info-row">
                      <div className="info-title">state</div>
                      <div className="info-name">{currentData.state}</div>
                    </div>
                    <div className="info-row">
                      <div className="info-title">zip code</div>
                      <div className="info-name">{currentData.postalCode}</div>
                    </div>
                    <div className="info-row">
                      <div className="info-title">country</div>
                      <div className="info-name">{currentData.country}</div>
                    </div>
                    <div className="info-row">
                      <div className="info-title">additional text</div>
                      <div className="info-name">{currentData.text}</div>
                    </div>
                  </div>
                </Modal>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Empty field={title} />
      )}
    </>
  );
};

export default RequestList;
