import { useState, useRef, useEffect } from "react";
import "./ImageUpload.css";

interface IImage {
  setFile: any;
  currentImage?: File;
}
const ImageUpload: React.FC<IImage> = ({ setFile }) => {
  const [dragOver, setDragOver] = useState(false);
  const [errorNotification, setErrorNotification] = useState("");
  const [dragOverClasses, setDragOverClasses] = useState("");
  const imageRef = useRef<HTMLInputElement>(null);

  const handleDragEnter = (e: any) => {
    e.preventDefault();
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
    if (!dragOver) {
      setDragOver(true);
    }
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setDragOver(false);
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    let file = e.dataTransfer.files[0];
    let fileType = file.type.split("/")[0];
    if (fileType !== "image") {
      console.log("Not an image file");
      setFile("");
      setErrorNotification("Not an image file");
      setDragOver(false);
      return setTimeout(() => {
        setErrorNotification("");
      }, 3000);
    }
    // document.getElementById("upload-image-input").fileList =
    //   e.dataTransfer.files[0];
    setFile(file);
    setDragOver(false);
  };

  // const handleAddImage = (e: any) => {
  //   e.preventDefault();
  //   let file = imageRef.current;
  //   let fileType = file.type.split("/")[0];
  //   if (fileType !== "image") {
  //     console.log("Not an image file");
  //     setFile("");
  //     setDragOverClasses("");
  //     setErrorNotification("Not an image file");
  //     return setTimeout(() => {
  //       setErrorNotification("");
  //     }, 3000);
  //   }
  //   setFile(file);
  // };
  const handleUploadImage = (e: any) => {
    e.preventDefault();
    if (e.image.files[0]) {
      console.log("Uploading Image " + e.image.files[0].name + "");
    }
  };
  const handleCancelUpload = (e: any) => {
    e.preventDefault();
    setFile("");
  };

  useEffect(() => {
    setDragOverClasses(dragOver ? "display-box drag-over" : "display-box");
  }, [dragOver]);

  return (
    <div className="image-uploader-wrapper">
      <div className={dragOverClasses}>
        <div className="icon-text-box">
          <div className="upload-icon">
            <i className="fa fa-upload" aria-hidden="true" />
          </div>
          {/* <div className="upload-text">{uploadText}</div> */}
          {errorNotification}
        </div>
        <div>
          <input
            type="file"
            ref={imageRef}
            id="upload-image-input"
            className="upload-image-input"
            accept="image/*"
            onDrop={handleDrop}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            // onChange={handleAddImage}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
