import "./Title.css";

interface ITitle {
  title?: string;
}

const Title: React.FC<ITitle> = (props) => {
  return <h1 className="title">{props.title}</h1>;
};

export default Title;
