import React from "react";
import "./Button.css";

const Button: React.FC = () => {
  return (
    <button className="button-wrapper">
      <i className="bx bxs-message-square-add button-icon"></i>
      <div>Add</div>
    </button>
  );
};

export default Button;
