import { useState } from "react";
import List from "../shared/list/List";
import Title from "../shared/title/Title";
import "./Media.css";
import MediaIcon from "../../assets/media.svg";
import Button from "../shared/Button/Button";

const Media: React.FC = () => {
  const [data, setData] = useState([
    {
      icon: MediaIcon,
      name: "file-name-1",
      size: "20kb",
      description: "this is a test text",
    },
    {
      icon: MediaIcon,
      name: "file-name-2",
      size: "20kb",
      description: "this is a test text",
    },
    { icon: MediaIcon, name: "file-name-3", size: "20kb" },
    { icon: MediaIcon, name: "file-name-4", size: "20kb" },
    { icon: MediaIcon, name: "file-name-5", size: "20kb" },
    { icon: MediaIcon, name: "file-name-6", size: "20kb" },
    { icon: MediaIcon, name: "file-name-7", size: "20kb" },
    { icon: MediaIcon, name: "file-name-8", size: "20kb" },
    {
      icon: MediaIcon,
      name: "file-name-1",
      size: "20kb",
      description: "this is a test text",
    },
    {
      icon: MediaIcon,
      name: "file-name-2",
      size: "20kb",
      description: "this is a test text",
    },
    { icon: MediaIcon, name: "file-name-3", size: "20kb" },
    { icon: MediaIcon, name: "file-name-4", size: "20kb" },
    { icon: MediaIcon, name: "file-name-5", size: "20kb" },
    { icon: MediaIcon, name: "file-name-6", size: "20kb" },
    { icon: MediaIcon, name: "file-name-7", size: "20kb" },
    { icon: MediaIcon, name: "file-name-8", size: "20kb" },
    {
      icon: MediaIcon,
      name: "file-name-1",
      size: "20kb",
      description: "this is a test text",
    },
    {
      icon: MediaIcon,
      name: "file-name-2",
      size: "20kb",
      description: "this is a test text",
    },
    { icon: MediaIcon, name: "file-name-3", size: "20kb" },
    { icon: MediaIcon, name: "file-name-4", size: "20kb" },
    { icon: MediaIcon, name: "file-name-5", size: "20kb" },
    { icon: MediaIcon, name: "file-name-6", size: "20kb" },
    { icon: MediaIcon, name: "file-name-7", size: "20kb" },
    { icon: MediaIcon, name: "file-name-8", size: "20kb" },
  ]);

  const changeData = (index: number) => {
    const newData = data.filter((e) => e !== data[index]);
    setData(newData);
    //todo push to server!
  };
  return (
    <div>
      <Title title="Media" />
      <div className="table">
        {/* <List
          headers={[" ", "File Name", "Size", "Description", "Edit", "Delete"]}
          data={data}
          changeData={changeData}
        ></List> */}
        <Button></Button>
      </div>
    </div>
  );
};

export default Media;
