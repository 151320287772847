import "./Modal.css";

interface IModal {
  toggler: boolean;
  children?: JSX.Element;
  close: any;
}

const Modal: React.FC<IModal> = ({ toggler, close, children }) => {
  return (
    <>
      <div
        className={toggler ? "overlay" : "closed-modal"}
        onClick={close}
      ></div>
      <div className={toggler ? "modal" : "closed-modal"}>
        <div className="modal-header">
          <i className="bx bx-window-close icon" onClick={close}></i>
        </div>
        {children}
      </div>
    </>
  );
};

export default Modal;
