import { stat } from "fs";
import "./Card.css";

interface ICard {
  children: JSX.Element;
  icon: string;
  title?: string;
  status?: boolean;
}
const Card: React.FC<ICard> = ({ children, icon, title, status }) => {
  return (
    <div className="card-container">
      <div className="card-header">
        <span className="card-icon">
          <i className={icon}></i>
        </span>
        <span className="card-title">{title}</span>
        <span
          className={status ? "card-status card-status-active" : "card-status"}
        ></span>
      </div>
      <div className="card-content">{children}</div>
    </div>
  );
};

export default Card;
